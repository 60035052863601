import {useToast} from "vue-toastification";
import i18n from "@/lang";
import store from "@/store";
import router from "@/router";
import IndexedDbFunctions from "@/assets/js/indexedDbFunctions";
import {appInstance} from "@/main";
import axios from "axios";

let logger;
export const state = {
  version: '0.5.07',
  development: process.env.NODE_ENV !== 'production',
  dbVersion: 3,
  dbName: 'sketchManagerFrontend',
  loggedInState: false,
  loadTitle: 'Lade Konfiguration',
  configLoader: {
    loadText: 'Bitte warten...',
    loadPercent: 0,
  },
  menu: null,
  publishNewVersion: false,
  apiToken: '42Aj4EUnVYfqZd8F8MHtBjve549pV6GdwVUu4qZJLjtvfRTApv77jMfm9CZ7zTUE',
  apiUrl: "https://api.sketch.media/",
  configs: {},
  employees: {},
  draftAttachments: [],
  customer: {}
}
export const mutations = {
  setDraftAttachments(state, payload) {
    state.draftAttachments = payload;
  },
  setConfig(state, payload) {
    state.configs[payload.key] = payload.data;
  },
  createStorage(state) {
    IndexedDbFunctions.addObject(state.dbName, 1, "core", {id: state.dbName, version: state.dbVersion});
  },
  saveJson(state) {
    IndexedDbFunctions.backup(state.dbName, state);
  },
  toggleConfigurator(state) {
    state.showConfig = !state.showConfig;
  },
  sidebarType(state, payload) {
    state.sidebarType = payload;
  },
  async setConfigLoader(state, payload) {
    state.configLoader = payload;
  },
  toggleSidebar(state) {
    state.sidebar = !state.sidebar;
  },
  handleApiResponse({commit}, shortResponse) {
    if (!shortResponse || !shortResponse.data || !shortResponse.data.todo) {
      return false;
    }
    const toast = useToast();
    let testMessage = shortResponse.data.todo.content;
    switch (shortResponse.data.todo.method) {
      case "showMessage":
        if (!i18n.de.toast.error[testMessage]) {
          toast.error(testMessage);
        } else {
          toast.error(i18n.de.toast.error[testMessage]);
        }
        break;
      case "logout":
        if (!i18n.de.toast.error[testMessage]) {
          toast.error(testMessage);
        } else {
          toast.error(i18n.de.toast.error[testMessage]);
        }
        store.commit("logout");
        break;
      case "logoutWithoutMessage":
        store.commit("logout");
        break;
      default:
        alert("default in handleApiResponse");
    }
  },
  handleApiError(state, customMessage) {
    if (!state || !state.feedback) {
      return false;
    }
    state.feedback.type = "error";
    state.feedback.render = true;
    state.feedback.messageClass = "error";
    if (customMessage === undefined) {
      state.feedback.message = "Leider hat diese Aktion nicht geklappt. Versuche es erneut!";
    } else {
      state.feedback.message = customMessage;
    }
    setTimeout(function () {
      state.feedback.render = false
    }, 6000);
  },
  showAllDoneMessage(state, message) {
    state.feedback.type = "message";
    state.feedback.render = true;
    state.feedback.messageClass = "message";
    if (message === undefined) {
      state.feedback.message = "Vielen Dank!";
    } else {
      state.feedback.message = message;
    }
    setTimeout(function () {
      state.feedback.render = false
    }, 6000);
  },
  showErrorMessage(state, message) {
    state.feedback.type = "error";
    state.feedback.render = true;
    state.feedback.messageClass = "error";
    if (message === undefined) {
      state.feedback.message = "Vielen Dank!";
    } else {
      state.feedback.message = message;
    }
    setTimeout(function () {
      state.feedback.render = false
    }, 6000);
  },
  changeLoggedIn(state, todo) { // or changeLoggedIn(state)
    let checkSwitch = state.loggedInState;
    if (todo === "toNull") {
      state.loggedInState = null;
      localStorage.removeItem("sketchManagerToken");
    } else if (todo === true) {
      state.loggedInState = true;
    } else {
      state.loggedInState = !state.loggedInState; // use with this.$store.commit("changeLoggedIn")
    }
    if (checkSwitch !== state.loggedInState && state.loggedInState === true) {
      // router.push("/");
    }
    if (checkSwitch !== state.loggedInState && state.loggedInState === false) {
      // returnUrl localStorage.setItem('returnUrl', to.path);
      router.push("/login");
    }
  },
  setToken(state, hash) { // or changeLoggedIn(state)
    state.token = !state.loggedInState; // use with this.$store.commit("changeLoggedIn")
    localStorage.setItem("sketchManagerToken", hash);
  },
  setAcl(state, acl) { // or changeLoggedIn(state)
    state.acl = acl; // use with this.$store.commit("changeLoggedIn")
  },
  setMenu(state, menu) {
    state.menu = menu;
  },
  setScope(state, acl) { // or changeLoggedIn(state)
    state.scope = acl; // use with this.$store.commit("changeLoggedIn")
  },
  setUser(state, user) { // or changeLoggedIn(state)
    state.user = user; // use with this.$store.commit("changeLoggedIn")
  },
  setApiToken(state, apiToken) { // or changeLoggedIn(state)
    state.apiToken = apiToken; // use with this.$store.commit("changeLoggedIn")
  },
  logout(state, redirect, route) {
    state.loggedInState = null;
    state.scope = {scope: null, write: false, loggedOut: true};
    state.acl = null;
    localStorage.removeItem("sketchManagerToken");
    if (redirect === undefined) {
      router.push("/signin");
    }
  },
  setFirstTimeLogin(state) {
    state.firstTimeLogin = false;
    localStorage.setItem("firstTimeLogin", "done");
  },
  forceSWupdate(state) {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
          registration.update();
        }
      })
    }
    if (localStorage.getItem("latest_cache_version") !== null && localStorage.getItem("latest_cache_version") !== 'null') {
      state.publishNewVersion = true;
    } else {
      state.publishNewVersion = false;
    }
  },
  setEmployees(state, payload) {
    state.employees = payload;
  },
  setCustomer(state, payload) {
    state.customer = payload;
  }
}
export const actions = {
  async getApiUrl({commit, state, dispatch}) {
    if (navigator.isOnline === undefined || navigator.isOnline) {
      let url = new URL(window.location.href);
      let host = url.host;
      await axios.get('https://api.sketch.media/urlAuth?domain=' + host).then(function (response) {
        const shortResponse = response.data.result
        if (shortResponse.success === true) {
          if (shortResponse.data) {
            state.apiUrl = shortResponse.data;
          }
          dispatch('checkToken');
        } else {
          commit('handleApiResponse', response)
        }
      }).catch(function (error) {
        commit('handleApiError', error)
      })
    }
  },
  toast({}, payload) {
    const toast = useToast();
    if (!i18n.de.toast[payload.type][payload.message]) {
      toast[payload.type](payload.message);
    } else {
      toast[payload.type](i18n.de.toast[payload.type][payload.message]);
    }
  },
  toastSuccess({dispatch}, payload) {
    dispatch("toast", {type: "success", message: payload});
  },
  toastWarning({dispatch}, payload) {
    dispatch("toast", {type: "warning", message: payload});
  },
  toastError({dispatch}, payload) {
    dispatch("toast", {type: "error", message: payload});
  },
  toastInfo({dispatch}, payload) {
    dispatch("toast", {type: "info", message: payload});
  },
  async getConfig({state}, config) {
    let key = config.type;
    if (config.subType !== undefined) {
      key = config.type + "_" + config.subType;
    }
    let returnVal;
    await IndexedDbFunctions.getObject(state.dbName, state.dbVersion, 'config', key).then(function (storedAssets) {
      returnVal = storedAssets;
    });
    return returnVal;
  },
  toggleSidebarColor({commit}, payload) {
    commit("sidebarType", payload);
  },
  copyToClipboard({commit}, copyText) {
    const toast = useToast();
    navigator.clipboard.writeText(copyText);
    toast.success(copyText + " kopiert!");
  },
  async configsLoaderProducts({dispatch}, loadFresh) {
    await dispatch("loadProducts", loadFresh);
  },
  async configsLoaderDrafts({dispatch}, loadFresh) {
    await dispatch("loadDrafts", loadFresh);
  },
  loadDraftAttachments({commit, getters}, payload) {
    const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
    axios.get(getters.getApiUrl + "draft/attachment/search", {headers}).then((r) => {
      commit("setDraftAttachments", r.data.result.data);
    });
  },
  async configsLoader({commit, dispatch, state}, loadFresh) {
    let configs = [
      {type: "employees", percent: 5, message: 'System wird gestartet...'},
      {type: "issue", subType: 'states', percent: 30, message: 'Dateien werden vom Server geladen...'},
      {type: "issue", subType: 'trackers', percent: 45, message: 'Kaffeemaschine wird gestartet...'},
      {type: "issue", subType: 'prios', percent: 87, message: 'Ende in Sicht... noch 13%!'},
      {type: "issue", subType: 'activities', percent: 98, message: 'fast fertig... noch 2%!'},
    ];
    let a;
    if (loadFresh) {
      for (a = 0; a < configs.length; a++) {
        await dispatch("loadConfig", configs[a]);
      }
      await commit("setConfigLoader", {loadPercent: 0});
    } else {
      // load from indexedDB
      let type;
      for (a = 0; a < configs.length; a++) {
        type = configs[a].type;
        if (configs[a].subType !== undefined) {
          type = type + "_" + configs[a].subType;
        }
        await IndexedDbFunctions.getObject(state.dbName, state.dbVersion, 'config', type).then(async (r) => {
          commit("setConfig", {key: type, data: r});
        });
      }
      await IndexedDbFunctions.getObjectList(state.dbName, state.dbVersion, 'products').then(async (r) => {
        commit("setProducts", r);
      });
      await IndexedDbFunctions.getObjectList(state.dbName, state.dbVersion, 'drafts').then(async (r) => {
      });
    }
    await dispatch("getEmployees");
    await dispatch("getCustomer");
  },
  login({commit, getters, dispatch}, payload) {


    if (appInstance) {
      logger = appInstance.config.globalProperties.$log;
    }
    if (!navigator.onLine) {
      commit("showErrorMessage", "Diese Funktion ist offline nicht verfügbar");
    } else {
      commit("changeLoggedIn", "toNull");
      axios.post(getters.getApiUrl + "login", payload).then(function (response) {
        let shortResponse = response.data.result;
        if (shortResponse.success === true) {
          commit("setToken", shortResponse.hash);
          const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
          axios.get(getters.getApiUrl + "checkMenu", {headers}).then(async (r) => {
            commit("setMenu", r.data.result.data);
            commit("setAcl", shortResponse.acl);
            commit("setUser", shortResponse.user);
            commit("setScope", shortResponse.scope);
            commit("setApiToken", shortResponse.apiToken);
            commit('changeLoggedIn', true);

            if (!shortResponse.user.crm_customer_id) {
              dispatch('toastError', 'Nur Kunden haben Zugriff!');
            } else {

              if (getters.getApiToken) {
                let pusher = new Pusher('d4e91a37c1c6462058ae', {cluster: 'eu'});
                let channel = pusher.subscribe(getters.getApiToken);
                channel.bind('updateConfig', function (data) {
                  if (data) {
                    dispatch("loadConfig", {type: data.type, subType: data.subType, percent: 0})
                  }
                });
              }

              // push to localStorage.setItem('returnUrl', to.path); if exists
              let returnUrl = localStorage.getItem("return2Url");
              await dispatch("configsLoader", true);
              if (payload.redirect) {
                window.location.href = window.location.origin + payload.redirect;

                await router.push(payload.redirect);
              } else {
                if (returnUrl) {
                  await router.push(atob(returnUrl));
                  localStorage.removeItem("return2Url");
                } else {
                  await router.push({name: "Tasks"});
                }
              }
            }
          });
        } else {

          dispatch("handleApiResponse", response.response);
        }
      }).catch(function (error) {

        dispatch("handleApiResponse", error.response);
      });
    }
  },
  async checkToken({commit, getters, dispatch}) {
    if (appInstance) {
      logger = appInstance.config.globalProperties.$log;
    }
    if (navigator.isOnline === undefined || navigator.isOnline) {
      let token = getters.getToken;
      if (token) {
        await axios.post(getters.getApiUrl + "checkToken", {token: token}).then(async function (response) {
          let shortResponse = response.data.result;
          if (shortResponse.success === true) {
            await commit("setToken", shortResponse.hash);
            await commit("setAcl", shortResponse.acl);
            await commit("setScope", shortResponse.user.scope);
            await commit('changeLoggedIn', true);
            await commit("setUser", shortResponse.user);
            await commit("setApiToken", shortResponse.apiToken);
            if (shortResponse.user.crm_customer_id) {
              const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
              axios.get(getters.getApiUrl + "checkMenu", {headers}).then(async (r) => {
                await commit("setMenu", r.data.result.data);
                await dispatch("configsLoader", false);
                var pusher = new Pusher('d4e91a37c1c6462058ae', {cluster: 'eu'});
                var channel = pusher.subscribe(getters.getApiToken);
                channel.bind('updateConfig', function (data) {
                  if (data) {
                    dispatch("loadConfig", {type: data.type, subType: data.subType, percent: 0})
                  }
                });
                return shortResponse.user.scope;
              });
            } else {
              const hash = window.location.search.split('issue=')[1];
              if (hash) {
              } else {
                await commit("logout");
              }
            }
          } else {
            commit("handleApiResponse", response);
          }
        }).catch(function (error) {
          commit("handleApiError", error);
        });
      }
    }
  },
  logout({commit}) {
    commit("logout");
  },
  handleApiError({commit}, error) {
    commit("handleApiError", error);
  },
  handleApiResponse({commit}, response) {
    commit("handleApiResponse", response);
  },
  async getPlugins({commit, getters}) {
    const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
    axios.get(getters.getApiUrl + "plugins", {headers}).then(function (response) {
      commit("setPlugins", response.data);
    }).catch(function (e) {
    });
  },
}
export const getters = {
  getConfigs: state => {
    return state.configs;
  },
  getToken() {
    return localStorage.getItem("sketchManagerToken");
  },
  getPlugins: state => {
    return state.plugins;
  },
  getApiToken: state => {
    return state.apiToken;
  },
  getEmployees: state => {
    return state.employees;
  },
  getUser: state => {
    return state.user;
  },
  checkLogin: state => {
    return state.loggedInState// use with this.$store.getters.checkLogin
  },
  getApiUrl: state => {
    return state.apiUrl;
  },
  getActiveCustomer: state => {
    return state.customer;
  },
  /**
   * @deprecated
   */
  getAuthorization() {
    return {"crmAuth": localStorage.getItem("sketchManagerToken")};
  },
  getMenu: state => {
    return state.menu;
  },
  getDraftAttachments: state => {
    return state.draftAttachments;
  }
}
