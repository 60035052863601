<template>
  <Modal>
    <div class="modal show"
         aria-hidden="true" style="display: block;">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header" style="border-bottom: 0px">
            <button type="button" class="btn-close" @click="$router.push('/affiliate')"></button>
          </div>
          <div class="modal-body text-center" style="padding: 40px; padding-top: 0;">
            <h2 style="color:#fd7e14 ">Partnerprogramm Antrag</h2>

            <div class="row" v-if="step && step !== '0'">
              <div class="col-12">
                <p v-if="step === '1'">
                  <p style="font-size: 18px;">Wir freuen uns, dass Du Dich für unser Partner-Programm interessierst.<br>Du bist nur zwei Schritte
                    entfernt!</p>
                  <input type="text" class="text-center form-control  w-50 mb-4" style=" margin-left: auto; margin-right: auto;" v-model="customer.c_firstname" @keyup.enter="nextStep()"
                                              placeholder="Vorname"/></p>
                <p v-if="step === '2'">
                  <span style="font-size: 18px;">Hallo {{ customer.c_firstname }}, wie lautet Deine E-Mail-Adresse?</span><br><br>
                  <input type="text" class="text-center form-control  w-50 mb-4" style=" margin-left: auto; margin-right: auto;" v-model="customer.c_email" :placeholder="'E-Mail'" @keyup.enter="nextStep()"/>
                  <input type="text" class="text-center form-control  w-50 mb-4" v-model="customer.c_phone" :placeholder="'Telefonnummer (optional)'" style="margin-left: auto; margin-right: auto;"   />
                </p>
                <p v-if="step === '3'">
                  <span style="font-size: 18px"><strong>Achtung - nicht schließen!</strong><br>Du erhältst ein 6-stelliges Passwort per Email zur Verifizierung! <br>Wie lautet das Passwort in deiner Mail?</span><br><br>
                  <input type="text" class="text-center form-control  w-50 mb-4" style=" margin-left: auto; margin-right: auto;" v-model="otp" placeholder="6-stelliges Passwort aus E-Mail" @keyup.enter="nextStep()"/>

                </p>
                <p v-if="showFeedback" v-html="feedback"></p>
                <button class="btn btn-outline-primary w-50" @click="nextStep()" v-if="showButton">Weiter</button>
              </div>
            </div>
            <div class="row" v-else>
              <div class="col-12">

                <p>Willst du Teil unseres Partner-Programms werden und 15% Provision für jede erfolgreiche Empfehlung
                  erhalten?</p>
                <button class="btn btn-outline-primary w-50" @click="nextStep()">Ja, ich will mich anmelden!</button>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import {mapGetters} from "vuex";
import axios from "axios";

export default {
  name: "AffiliateCreate",
  data() {
    return {

      step: '1',
      customer: {
        c_firstname: '',
        c_email: '',
        c_phone: ''
      },
      customerId: '',
      otp: '',
      feedback: '',
      showFeedback: false,
      showButton: true,
    };
  },
  computed: {
    ...mapGetters(['getUser', 'getApiUrl'])
  },
  components: {},
created() {
    if (this.getUser && this.getUser.crm_customer_id) {
      this.step = '0'
    }
},
  methods: {
    nextStep() {
      if (this.step === '0') {
        const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
        axios.post(this.getApiUrl + "customer/affiliate", null, {headers}).then((r) => {
          if (r.data.result.success) {
            // reload window
            window.location.href = '/';
          } else {
            this.showFeedback = true;
            this.feedback = r.data.result.message;
          }
        }).catch((error) => {
          this.showFeedback = true;
          this.feedback = 'Ein Fehler ist aufgetreten. Bitte versuche es später erneut.';
        });
      }
      if (this.step === '1') {
        if (this.customer.c_firstname.length > 0) {
          this.step = '2';
        } else {
          this.showFeedback = true;
          this.feedback = 'Bitte gib Deinen Vornamen ein.';
        }
      } else if (this.step === '2') {
        if (this.customer.c_email.length > 0) {


          axios.post(this.getApiUrl + "customer/createAffiliate", {

              c_firstname: this.customer.c_firstname,
              c_email: this.customer.c_email

          }).then((r) => {
            if (r.data.result.success) {
              this.step = '3';
              this.customerId = r.data.result.data;
            } else {
              this.showFeedback = true;
              this.feedback = r.data.result.message;
            }
          }).catch((error) => {
            this.showFeedback = true;
            this.feedback = 'Ein Fehler ist aufgetreten. Bitte versuche es später erneut.';
          });


        } else {
          this.showFeedback = true;
          this.feedback = 'Bitte gib Deine E-Mail-Adresse ein.';
        }
      } else if (this.step === '3') {
        if (this.otp.length > 0) {

          axios.post(this.getApiUrl + "customer/activateAffiliateWithOTP", {
            crm_customer_id: this.customerId,
            otp: this.otp
          }).then((r) => {
            if (r.data.result.success) {
              this.showFeedback = true;
              this.feedback = "Vielen Dank für Deine Anmeldung. Du kannst dich jetzt einloggen auf <a href='https://mein.sketch.media'>mein.sketch.media</a>";
              this.showButton = false;
            } else {
              this.showFeedback = true;
              this.showButton = false;
              this.feedback = r.data.result.message;
            }
          }).catch((error) => {
            this.showFeedback = true;
            this.showButton = false;
            this.feedback = 'Ein Fehler ist aufgetreten. Bitte versuche es später erneut.';
          });
        } else {
          this.showFeedback = true;
          this.feedback = 'Bitte gib das Passwort aus der E-Mail ein.';
        }
      }
    }
  },
};
</script>
<style scoped lang="scss">
.btn-outline-primary{
  color: #fd7e14;
  border-color: #fd7e14;
  &:hover{
    color: #fff;
    background: #fd7e14;
  }
}
input.form-control:focus{
  border-color: #fd7e14;
  box-shadow: 0 0 0 0.25rem rgba(253, 126, 20, 0.25);
}
</style>
