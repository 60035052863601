<template>
  <div id="publicIssueWrapper" class="wrapper open Tasks" :class="$route.name">
    <div class="pleaseLogin">
      <div class="pleaseLoginInner">
        <div class="row">
          <div class="col-lg-2 col-12">
            <div class="brandingLogo" v-if="getConfigs && getConfigs.params_values"><img v-if="getConfigs.params_values.image_rechnung" :src="getConfigs.params_values.image_rechnung"/></div>
            <img v-else :src="logo"/>
          </div>
          <div class="col-lg-4 col-12">&nbsp;</div>
          <div class="col-lg-6">
            <div class="warningsBox">
            <div class="row">
              <div class="col-lg-12 col-12"><p class="text-center mb-2">{{ $t("globals.pleaseLoginText") }} <button @click="showLogin = true" class="ms-3 mt-1 mb-2 btn btn-outline-primary btn-purple"><i class="fa-duotone fa-user"></i> {{
                  $t("globals.login")
                }}
              </button></p></div>
               </div>
            </div>
          </div>

        </div>

      </div>
    </div>
    <template v-if="isClosed">
      <h1 class="text-center mt-4">Ticket geschlossen</h1>
      <h2 class="text-center"><img :src="require('@/assets/img/task_closed.gif')" /></h2>
    </template>
    <div class="appView tasks purpleView" v-else>
      <div class="card" v-if="taskItem">
        <div class="card-header">
          <h6 class="editLine">{{ taskItem.title }}</h6>
        </div>
        <ul class="tabNav">
          <li v-for="cat in taskTabs" :key="cat.name"
              @click="changeTab(cat.name)"
              :class="(activeSubtab === cat.name) ? 'active' : ''">
            <template v-if="cat.name === 'comments' && taskItem._countComments"><span
              class="incNumber">{{ taskItem._countComments }}</span></template>
            <template v-if="cat.name === 'attachments' && taskItem._countAttachments "></template>
            <template v-if="cat.name === 'subtasks' && taskItem._countIssues"><span
              class="incNumber">{{ taskItem._countIssues }}</span></template>

            <i class="fa-duotone" :class="cat.icon"></i>
            <span class="d-none d-sm-inline">{{ cat.label }}</span>
          </li>
          <li @click="showLogin = true">
            <span
              class="incNumber">{{ taskItem._countAttachments }}</span>
            <i class="fa-duotone fa-paperclip"></i> <span class="d-none d-sm-inline">Anhänge</span>
          </li>
        </ul>

        <div class="card-body" v-if="activeSubtab === 'details'">
          <div class="taskDetails">
            <div class="infoBlock text-xs mb-2"><i
              class="fa-duotone fa-calendar-circle-plus"></i> {{ $t("tasks.details.created") }}
              {{ $filters.formatDate(taskItem.crm_created_date) }} |
              <i
                class="fa-duotone fa-calendar-circle-user"></i> {{ $t("tasks.details.processed") }}
              {{ $filters.formatDateTime(taskItem.crm_modified_date) }}
            </div>
            <div class="row mb-2">
              <div class="col-lg-9 col-xl-10 col-12">
                <hr>
                <template v-if="taskItem.descVisible === '1'">
                <label class="color">{{ $t("globals.description") }}</label>
                <div class="taskDesc" v-html="taskItem.description"></div>
                <hr>
                </template>
              </div>
              <div class="col-lg-3 col-xl-2 col-12 rightCol">

                <div class="externalResource mb-3 col">
                  <label class="color">{{ $t("tasks.taskNr") }}</label>
                  <span>{{ taskItem.id }}/{{ taskItem.hash }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="subTasks" v-if="activeSubtab ==='subtasks'">
          <PublicSubtasks :task="taskItem" v-if="taskItem" @triggerLogin="showLogin=true"></PublicSubtasks>
        </div>
        <div class="taskComments" v-if="activeSubtab === 'comments'">
          <PublicComments :task="taskItem" v-if="taskItem"/>
        </div>
      </div>
    </div>
    <Modal v-if="showLogin">
      <div class="modal fade show " tabindex="-1"
           aria-hidden="true" style="display: block;">
        <div class="modal-dialog modal-md ">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Anmeldung</h5>
              <button type="button" class="btn-close" @click="showLogin = false"></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
<Login layout="small" :redirectAfterLogin="'/tasks/'+$route.params.id" />

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import PublicComments from "@/routing/core/Tasks/PublicComments.vue";
import axios from "axios";
import Editor2 from "@/components/Editor2.vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import VueMultiselect from 'vue-multiselect';
import PublicSubtasks from "@/routing/core/Tasks/Layouts/PublicSubtasks.vue";
import Timesheet from "@/routing/core/Tasks/Layouts/Timesheet.vue";
import Login from "@/routing/core/Login/Signin.vue";
import $ from "jquery";
export default {
  name: "Details",
  components: {
    PublicComments, Editor2, VueDatePicker, VueMultiselect, PublicSubtasks, Timesheet, Login
  },
  computed: {
    ...mapGetters(["customerActive", "getEmployees", "modalsList", "taskActive", "getConfigs", "getUser"])
  },
  data() {
    return {
      taskItem: {},
      editorVisible: true,
      showLogin: false,
      taskTabs: [
        {name: 'details', label: this.$t('tasks.labels.details'), icon: 'fa-memo-circle-info'},
        {name: 'subtasks', label: this.$t('tasks.labels.subtasks'), icon: 'fa-list-tree'},
        {name: 'comments', label: this.$t('tasks.labels.comments'), icon: 'fa-comments'},
        // {name: 'timesheet', label:'Zeitaufwand', icon: 'fa-stopwatch'},
      ],
      activeSubtab: 'details',
      logo: require("@/assets/img/crm_pdf_logo_new.png"),
      editStart: false,
      editDue: false,
      editAssignee: false,
      editState: false,
      editPrio: false,
      editTracker: false,
      editExtFollower: false,
      editIntFollower: false,
      editProject: false,
      editCustomer: false,
      addresses: [],
      projects: [],
      isClosed: false,
    }
  },
  methods: {

    closeTask() {
      this.$store.dispatch("tasksFilter");
      this.$store.dispatch('setTask', null);
      this.$router.push({name: "Tasks"});
    },
    getSingleTask() {
      const hash = window.location.search.split('issue=')[1];
      axios.get(this.$store.getters.getApiUrl + "issue?hash=" + hash).then((response) => {
        this.taskItem = response.data.result.data;
        this.taskItem.description = this.$filters.renderLineBreaks(this.taskItem.description);
        this.$store.dispatch('setTask', this.taskItem);
        //this.getAddresses();
        //this.getProjects();
        this.scrollToTop();
        //this.$store.commit("setTicket", r.data.result.data);
      }).catch((error) => {
        this.$store.dispatch("handleApiResponse", error.response);
        this.isClosed = true
      });
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
    getProjects() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "project/search?filter_customer=" + this.taskItem.customer.id + "&filter_state=active", {headers}).then((r) => {
        this.projects = r.data.result.data;
      });
    },
    getTitleById(id, type) {
      if (this.getConfigs[type] !== undefined) {
        return Object.values(this.getConfigs[type]).find(issueState => issueState.id === id)?.title || 'keine';
      } else {
        return "keine";
      }
    },
    getAddresses() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "customer/" + this.taskItem.crm_customer_id + "/addresses", {headers}).then((r) => {
        this.addresses = r.data.result.data;
      });
    },
    correctId(key) {
      this.taskItem[key] = this.taskItem[key].id;
      this.updateTask();
      this.editAssignee = false;
      this.editState = false;
      this.editTracker = false;
      this.editPrio = false;
      this.editProject = false;
    },
    editItem(field) {
      const value = event.target.innerText;
      this.taskItem[field] = value;
      this.updateTask();
    },
    updateTask() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      const hash = this.taskItem.customer.hash;
      let postContent = {
        data: this.taskItem,
        hash: hash
      }
      axios.put(this.$store.state.apiUrl + 'issue/' + this.taskItem.id, postContent, {headers})
        .then(response => {
          this.taskItem = response.data.result.data;
          this.$store.dispatch('setTask', this.taskItem);
          this.editStart = false;
          this.editDue = false;
        })
        .catch(e => {
          this.$store.dispatch("handleApiResponse", e);
        });
    },
    checkTask() {
      // todo-ralf: correct state_id after API is ready
      this.taskItem.state_id = 2;
      this.updateTask();
      this.$router.push({name: "Tasks"});
      //this.$store.dispatch("tasksFilter");
      // Await refresh then this.closeTask
      setTimeout(() => {
        this.closeTask();
      }, 1000);
    },
    deleteTask(id) {
      this.$confirm(this.$t('tasks.details.detailsTextDelete'), this.$t('tasks.deleteTask'), {
        confirmButtonText: this.$t('globals.yes'),
        cancelButtonText: this.$t('globals.no'),
        type: 'warning'
      }).then(() => {
        const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
        axios.delete(this.$store.state.apiUrl + 'issue/' + id, {headers})
          .then(response => {
            this.$store.dispatch('setTask', null);
            this.$router.push({name: "Tasks"});
            this.$store.dispatch('toastSuccess', this.$t('toast.success.taskDeleted'));
          })
          .catch(e => {
            this.$store.dispatch("handleApiResponse", e.response);
          });
      }).catch(e => {
        console.log(e);
      });
    },
    isValidTab(tab) {
      return this.taskTabs.some((taskTab) => taskTab.name === tab);
    },
    changeTab(tabName) {
      this.activeSubtab = tabName;
    },
    hideEditor() {
      this.editorVisible = false;
    },
    showEditor() {
      this.editorVisible = true;
    },
  },
  created() {

    if(this.getUser){
    console.log("user", this.getUser);
      this.$router.push({name: "TaskDetails", params: {id: this.$route.params.id}});
    }
    this.getSingleTask();
    if(!this.$store.state.configs.employees) {
      this.$store.dispatch("loadConfig", {type: "employees", public: true, percent: 56, message: 'Daten werden geladen...'}).then(() => {

        this.$store.state.configLoader.loadPercent = 0;
      });
    }
    window.addEventListener("triggerReloadFiles", this.getSingleTask);
  },
  mounted() {
    Pusher.logToConsole = false;
    var pusher = new Pusher('d4e91a37c1c6462058ae', {cluster: 'eu'});
    let VueInner = this;
    var channel = pusher.subscribe(this.$store.state.apiToken);
    channel.bind('issue-' + this.$route.params.id, function (data) {
      VueInner.getSingleTask();
    });
  },
  watch: {
    taskActive() {
    },
    $route(to) {
      if (to.params.tab) {
        this.activeSubtab = to.params.tab;
      } else {
        this.activeSubtab = 'details';
      }
      if (to.params.id) {
        this.getSingleTask();
      }
    }
  },
}
</script>
<style>
.multiselect.small.open .multiselect__select:before {
  display: none;
}

.col-lg-3.col-xl-2.col-12.rightCol p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
